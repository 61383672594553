import { useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { messages } from "../global-intl-messages";
import { useParticipationItemUrl } from "../hooks/useParticipationItemUrl";
import Button from "../shared/components/Button";
import {
  findAnswer,
  isBooleanQuestion,
  isChoiceQuestion,
  isFileUploadQuestion,
  isLocationQuestion,
  isRatingQuestion,
  isTextQuestion,
  isTrackingQuestion,
  useSurveyState,
} from "../SurveyContext";
import {
  booleanAnswered,
  minimumPinsPlaced,
  optionSelected,
  ratingAnswered,
  trackingAnswered,
} from "../utils/surveyUtils";
import { validateTextQuestion } from "./OpenQuestion";
import { PageModel } from "./EndUserApi";

export const SurveyNavigation = () => {
  const { surveyState, setSurveyState, onCompleteFn, api } = useSurveyState();
  const { survey, activePage } = surveyState;
  const participationItemUrl = useParticipationItemUrl();
  const history = useHistory();

  const index = useMemo(() => {
    return activePage ? survey?.pages.indexOf(activePage) ?? 0 : 0;
  }, [activePage, survey]);

  const setActivePage = useCallback(
    (page?: PageModel) => {
      setSurveyState((prevState) => ({
        ...prevState,
        activePage: page,
        activeView: undefined
      }));
    },
    [setSurveyState]
  );

  const previousPage = useMemo(() => survey?.pages[index - 1], [index, survey?.pages]);
  const nextPage = useMemo(() => survey?.pages[index + 1], [index, survey?.pages]);

  const canContinue = useMemo(() => {
    if (!activePage) return false;
    return (
      activePage.elements
        .filter(isLocationQuestion)
        .every((e) => minimumPinsPlaced(e, surveyState.answers.find(findAnswer(e)))) &&
      activePage.elements
        .filter(isTextQuestion)
        .every((e) => validateTextQuestion(e, surveyState.answers.find(findAnswer(e)))) &&
      activePage.elements
        .filter(isFileUploadQuestion)
        .every((e) => !e.required || !!surveyState.answers.find(findAnswer(e))?.amountOfFiles) &&
      activePage.elements
        .filter(isChoiceQuestion)
        .every((e) => optionSelected(e, surveyState.answers.find(findAnswer(e)))) &&
      activePage.elements
        .filter(isBooleanQuestion)
        .every((e) => booleanAnswered(e, surveyState.answers.find(findAnswer(e)))) &&
      activePage.elements
        .filter(isRatingQuestion)
        .every((e) => ratingAnswered(e, surveyState.answers.find(findAnswer(e)))) &&
      activePage.elements
        .filter(isTrackingQuestion)
        .every((e) => trackingAnswered(e, surveyState.answers.find(findAnswer(e))))
    );
  }, [activePage, surveyState]);

  const onComplete = useCallback(async () => {
    onCompleteFn().then(async (r) => {
      if (r && r.status === 200) {
        await Promise.all(
          r.data.map(async (e) => {
            const files = surveyState.files?.[e.questionId];
            if (files?.length) {
              for (const file of files) {
                return api.files
                  .uploadFile(e.answerId, { file })
                  .catch((e) =>
                    console.error(`[Tim.Survey.FileUpload]: Upload failed (${file.name})`)
                  );
              }
            } else {
              console.error(`[Tim.Survey.FileUpload]: No files found for question ${e.questionId}`);
            }
          })
        );
      }
      setSurveyState((prevState) => ({
        ...prevState,
        answers: [],
        activePage: undefined,
        activeQuestionId: undefined,
        activePinId: undefined,
        files: {},
      }));
      history.push(participationItemUrl());
    });
  }, [api.files, history, onCompleteFn, participationItemUrl, setSurveyState, surveyState.files]);

  return (
    <StyledSurveyNavigation>
      <div>
        {previousPage && (
          <Button onClick={() => setActivePage(previousPage)}>
            <FormattedMessage {...messages.previousButton} />
          </Button>
        )}
      </div>
      <div>
        {nextPage ? (
          <Button
            onClick={() => setActivePage(nextPage)}
            className={canContinue ? "primary" : "disabled"}
            disabled={!canContinue}
          >
            <FormattedMessage {...messages.nextButton} />
          </Button>
        ) : (
          <Button
            onClick={onComplete}
            className={canContinue ? "primary" : "disabled"}
            disabled={!canContinue}
          >
            <FormattedMessage {...messages.finishButton} />
          </Button>
        )}
      </div>
    </StyledSurveyNavigation>
  );
};

const StyledSurveyNavigation = styled.div`
  display: flex;
  border-top: 1px solid var(--color-neutral-10);
  padding: 1rem 0;
  padding-bottom: 0;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 1rem;
`;
