import { useCallback, useMemo } from "react";
import styled from "styled-components";
import { findAnswer, useSurveyState } from "../SurveyContext";
import { ChoiceQuestionModel } from "./EndUserApi";

type Props = {
  question: ChoiceQuestionModel;
};

export const ChoiceQuestion = ({ question }: Props) => {
  const { setSurveyState, surveyState } = useSurveyState();

  const answer = useMemo(() => {
    return surveyState.answers.find(findAnswer(question));
  }, [surveyState.answers, question]);

  const updateCheckbox = useCallback(
    (value: string) => {
      const updatedAnswer = answer ?? {
        questionId: question.id,
        type: "ChoiceAnswer",
        choices: [],
      };

      const choice = updatedAnswer.choices.find((c) => c.value === value);

      if (choice) choice.selected = !choice.selected;

      setSurveyState((prevState) => ({
        ...prevState,
        answers: [...prevState.answers.filter((a) => a !== answer), updatedAnswer!],
      }));
    },
    [answer, question.id, setSurveyState]
  );

  const updateRadio = useCallback(
    (value: string) => {
      const updatedAnswer = answer ?? {
        questionId: question.id,
        type: "ChoiceAnswer",
        choices: [],
      };

      updatedAnswer.choices = question.options.map((o) => ({
        value: o.value,
        selected: o.value === value,
      }));

      setSurveyState((prevState) => ({
        ...prevState,
        answers: [...prevState.answers.filter((a) => a !== answer), updatedAnswer!],
      }));
    },
    [answer, question.id, question.options, setSurveyState]
  );

  return (
    <ChoiceQuestionContainer>
      {question.title && (
        <h3>
          {question.title} {question.required && "*"}
        </h3>
      )}
      {question.content && <p>{question.content}</p>}
      {question.allowMultiple
        ? question.options.map((choice) => {
            return (
              <InputLabel htmlFor={`key-${question.id}-${choice.value}`} key={choice.value}>
                <input
                  type="checkbox"
                  onChange={() => updateCheckbox(choice.value)}
                  id={`key-${question.id}-${choice.value}`}
                  checked={answer?.choices
                    ?.filter((c) => c.selected)
                    .map((c) => c.value)
                    .includes(choice.value)}
                />
                {choice.label}
              </InputLabel>
            );
          })
        : question.options.map((choice) => {
            return (
              <InputLabel htmlFor={`key-${question.id}-${choice.value}`} key={choice.value}>
                <input
                  type="radio"
                  name={question.id.toString()}
                  onChange={() => updateRadio(choice.value)}
                  id={`key-${question.id}-${choice.value}`}
                  checked={answer?.choices
                    ?.filter((c) => c.selected)
                    .map((c) => c.value)
                    .includes(choice.value)}
                />
                {choice.label}
              </InputLabel>
            );
          })}
    </ChoiceQuestionContainer>
  );
};

const ChoiceQuestionContainer = styled.div`
  margin: 1rem 0;
`;

const InputLabel = styled.label`
  display: flex;
  width: fit-content;
  padding-right: var(--space-30);
  margin-bottom: 8px;
  align-items: center;
  background-color: var(--color-neutral-10);
  border-radius: var(--border-radius-10);
  color: black;

  > input {
    margin: var(--space-20);
  }
`;
