import React, { useMemo } from "react";
import { Marker as MapMarker } from "react-map-gl";
import { useSetMapState } from "../../hooks/useSetMapState";
import { getTokenValue } from "../../TokenStyles";
import { LayerGroupArea } from "../../types";
import { makeKeyDownHandler } from "../../utils/a11y-utils";
import { calculateViewport } from "../../utils/mapUtils";
import { LabelStyles } from "./MapStyles";

type MarkerProps = {
  area: LayerGroupArea;
  minimumZoom: number;
};

function AreaMarker({ area, minimumZoom }: MarkerProps) {
  const setMapState = useSetMapState();

  const center = useMemo(() => {
    const lon = (area.boundingBox[0][0] + area.boundingBox[1][0]) * 0.5;
    const lat = (area.boundingBox[0][1] + area.boundingBox[1][1]) * 0.5;
    return { lat, lon };
  }, [area]);

  const onClick = (e: any) => {
    e.stopPropagation();
    const viewport = calculateViewport(area.boundingBox);
    const zoomlevel = viewport.zoom < minimumZoom ? minimumZoom : viewport.zoom;
    setMapState(viewport.latitude, viewport.longitude, zoomlevel);
  };
  const title = area.title.match(/.{1,35}(\s|$)/g);
  return (
    <MapMarker latitude={center.lat} longitude={center.lon}>
      <LabelStyles
        style={area.color ? { backgroundColor: getTokenValue(area.color) } : undefined}
        onClick={onClick}
        onKeyDown={makeKeyDownHandler(onClick)}
        tabIndex={0}
      >
        {title!.map((i, key) => {
          return <div key={key}>{i}</div>;
        })}
      </LabelStyles>
    </MapMarker>
  );
}

export const MemoizedAreaMarker = React.memo(AreaMarker);
